import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import SectionHeader from '../../components/SectionHeader';
import Loading from '../../components/Loading';
import axios from 'axios';

const EventsDetails = () => {
    const { id } = useParams();
    const [eventDetails, setEventDetails] = useState(null); 
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const fetchAuthorEvents = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/single-author-event/${id}`);
                setEventDetails(response.data.data);
            } catch (error) {
                console.error('Error fetching event details!', error);
            } finally {
                setLoading(false);
            }
        };
        fetchAuthorEvents();
    }, [id]);
    return (
        <>
            <section className='event-details'>
                <SectionHeader title={eventDetails?.venue || 'Loading...'} PageLink="Author Events Details"></SectionHeader>
                <Container>
                    {loading && <Loading />}
                    {eventDetails ? (
                        <div className="event-info">
                            <h1>{eventDetails.venue}</h1>
                            <h2>{new Date(eventDetails.date).toDateString()}</h2>
                            <p><strong>Reason:</strong> <div>{eventDetails.reason} </div></p>
                            <p><strong>Comment:</strong> <span dangerouslySetInnerHTML={{ __html: eventDetails.comment }} /></p>

                        </div>
                    ) : (
                        !loading && <p>Event details not available.</p>
                    )}
                </Container>
            </section>
        </>
    );
};

export default EventsDetails;

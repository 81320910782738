import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import SectionHeader from '../../components/SectionHeader';
import Loading from '../../components/Loading';

const SingleAuthorBlog = () => {
    const [formData, setFormData] = useState({
        name: '',
        subject: '',
        comment : '',
    });

    const { id } = useParams(); 
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/single-author-blogs/${id}`);
                setBlog(response.data.data);
                // console.log("single blog", response.data);
            } catch (err) {
                setError('Error fetching blog data');
                // console.error('Error fetching blog data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchBlog();
    }, [id, baseUrl]);


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    }


    if (loading) return <p><Loading /></p>;
    if (error) return <p className="text-danger"><Loading /></p>;
    if (!blog) return <p>No blog found.</p>;


    return (
        <>
            <section>
                <SectionHeader title={blog.title} PageLink={blog.title}></SectionHeader>
                <div className="in-page-container pt-5 pb-5">
                    <div className="about-section top__about">
                        <Container>
                            <Row className='inner__about'>
                                <Col md={5}>
                                    <div className="about-img img-holder">
                                        <img src={`${baseUrl}/storage/app/${blog.blog_images}`} alt="Hero Img" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div class="about-text">
                                        <h2>{blog.title}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: blog.description }} />
                                    </div>
                                </Col>
                            </Row>
                            <div className='blog-comment'>
                                <form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <input placeholder="Name" type="text" name="name" value={formData.name} onChange={handleChange} className="form-control" required />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <input placeholder="Subject" type="text" name="subject" value={formData.subject} onChange={handleChange} className="form-control" required />
                                        </Col>
                                        <Col md={12} className="mb-3">
                                            <textarea placeholder="Comment " name="comment " value={formData.comment} onChange={handleChange} className="form-control" rows="4" />
                                        </Col>
                                        <button type="submit" className="blue-btn btn-style round-btn btn btn-link" disabled={loading}>
                                            {loading ? 'Saving...' : 'Save Comment '}
                                        </button>
                                    </Row>
                                </form>
                            </div>
                        </Container>
                    </div>
                </div>
            </section>
        </>
    );
}

export default SingleAuthorBlog;
import React, { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import Loading from '../../components/Loading';
import SectionHeader from '../../components/SectionHeader';
import { Link } from 'react-router-dom';
const AuthorEvents = () => {
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState([]);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL; 
        const fetchAuthorEvents = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/show-author-event`);
                setEvents(Object.values(response.data));
            } catch (error) {
                console.error('There was an error fetching the data!', error);
            } finally {
                setLoading(false); 
            }
        };

        fetchAuthorEvents();
    }, []);
    // Add event comment as Button ClassName
    const tileClassName = ({ date, view }) => {
        if (view === 'month') {
            const dayColor = events.some(event =>
                new Date(event.date).toDateString() === date.toDateString()
            );
            return dayColor ? 'event-date' : null;
        }
    };
    // Add event comment as tile content
    const tileContent = ({ date, view }) => {
        if (view === 'month') {
            const event = events.find(event =>
                new Date(event.date).toDateString() === date.toDateString()
            );
            return event ? <Link to={`/events-details/${event.id}`} className='calendar-link'><p>{event.userid}</p><p>{event.venue}</p></Link> : null;
        }
    };
    return (
        <>
            <section>
                <SectionHeader title="Author Events" PageLink="Author Events"></SectionHeader>
                <Container>
                    <div className="community-main">
                        {loading && <Loading />}
                        <Calendar
                            onChange={setDate}
                            value={date}
                            tileClassName={tileClassName}
                            tileContent={tileContent}
                        />
                        {/* <div>
                            <h3>All Events:</h3>
                            {events.map(({ id, date, comment }) => (
                                <div key={id}>
                                    <p>Date: {new Date(date).toDateString()}</p>
                                    <p>Comment: {comment}</p>
                                </div>
                            ))}
                        </div> */}
                    </div>
                </Container >
            </section >
        </>
    )
}

export default AuthorEvents
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';

const AuthorBlog = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const imageInputRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userid: '',
        title: '',
        type: '',
        date: '',
        description: '',
        image: null,
    });


    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate('/login');
        } else {
            try {
                const parsedUser = JSON.parse(user);
                setFormData((prevState) => ({
                    ...prevState,
                    userid: parsedUser.name,
                }));
            } catch (err) {
                console.error('Error parsing user data from localStorage:', err);
                navigate('/login');
            }
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "image") {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowAlert(false);

        try {
            const data = new FormData();
            data.append('userid', formData.userid);
            data.append('title', formData.title);
            data.append('type', formData.type);
            data.append('date', formData.date);
            data.append('description', formData.description);
            if (formData.image) {
                data.append('image', formData.image);
            }
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/author-blogs-store', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAlertType('success');
            setAlertMessage('Blog stored successfully!');
            setShowAlert(true);
            // Reset the form after success
            setFormData({
                userid: formData.userid,
                title: '',
                type: '',
                date: '',
                description: '',
                image: null,
            });
            // Clear the file input
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
            setTimeout(() => setShowAlert(false), 3000);

        } catch (err) {
            setAlertType('error');
            setAlertMessage('Failed to store blog. Please try again.');
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="dash-card">
                <Card className="dash-sub-card">
                    <Card.Header as="h4" className="dash-card-header">Author Blog</Card.Header>
                    <Card.Body>
                        {loading && <Loading />}
                        {showAlert && (
                            <Alert
                                variant={alertType === 'success' ? 'success' : 'danger'}
                                onClose={() => setShowAlert(false)}
                                dismissible
                            >
                                {alertMessage}
                            </Alert>
                        )}
                        <Container>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <input placeholder="Blog Title" type="text" name="title" value={formData.title} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <input placeholder="Blog Type" type="text" name="type" value={formData.type} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <input placeholder="Date" type="date" name="date" value={formData.date} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <textarea placeholder="Blog Description" name="description" value={formData.description} onChange={handleChange} className="form-control" rows="4" />
                                    </Col>
                                    <Col md={12} className='mb-3'>
                                        <input type="file" name="image" onChange={handleChange} accept="image/*" className="form-control" ref={imageInputRef} />
                                    </Col>
                                    <button type="submit" className="blue-btn btn-style round-btn btn btn-link" disabled={loading}>
                                        {loading ? 'Saving...' : 'Save Blog'}
                                    </button>
                                </Row>
                            </form>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default AuthorBlog
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';

const CreateEvent = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userid: '',
        date: '',
        venue: '',
        reason: '',
        comment: '',
    });


    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate('/login');
        } else {
            try {
                const User = JSON.parse(user);
                setFormData((prevState) => ({
                    ...prevState,
                    userid: User.name,
                }));
            } catch (err) {
                console.error('Error parsing user data from localStorage:', err);
                navigate('/login');
            }
        }
    }, [navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowAlert(false);

        try {
            const data = new FormData();
            data.append('userid', formData.userid);
            data.append('date', formData.date);
            data.append('venue', formData.venue);
            data.append('reason', formData.reason);
            data.append('comment', formData.comment);
            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/author-event-store', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAlertType('success');
            setAlertMessage('Author Event stored successfully!');
            setShowAlert(true);
            // Reset the form after success
            setFormData({
                userid: formData.userid,
                date: '',
                venue: '',
                reason: '',
                comment: '',
            });
            // Clear the file input
            setTimeout(() => setShowAlert(false), 3000);

        } catch (err) {
            setAlertType('error');
            setAlertMessage('Failed to store Author Event. Please try again.');
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="dash-card">
                <Card className="dash-sub-card">
                    <Card.Header as="h4" className="dash-card-header">Create Author Event</Card.Header>
                    <Card.Body>
                        {loading && <Loading />}
                        {showAlert && (
                            <Alert
                                variant={alertType === 'success' ? 'success' : 'danger'}
                                onClose={() => setShowAlert(false)}
                                dismissible
                            >
                                {alertMessage}
                            </Alert>
                        )}
                        <Container>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={12} className="mb-3">
                                        <div>
                                            <label className="form-label" htmlFor="Date">Date <span className='red_star'>*</span></label>
                                            <input placeholder="Date" type="date" name="date" id='Date' value={formData.date} onChange={handleChange} className="form-control form_date" required />
                                        </div>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <div>
                                            <label className="form-label" htmlFor="Venue">Venue <span className='red_star'>*</span></label>
                                            <input placeholder="Venue" type="text" name="venue" id='Venue' value={formData.venue} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <div>
                                            <label className="form-label" htmlFor="Reason">Reason <span className='red_star'>*</span></label>
                                            <input placeholder="Reason" type="text" name="reason" id='Reason' value={formData.reason} onChange={handleChange} className="form-control" required />
                                        </div>
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <label className="form-label" htmlFor="Comment">Comment <span className='red_star'>*</span></label>
                                        <textarea placeholder="Comment" name="comment" id="Comment" value={formData.comment} onChange={handleChange} className="form-control" rows="4" />
                                    </Col>
                                    <Col md={12} className="">
                                        <button type="submit" className="blue-btn btn-style round-btn btn btn-link" disabled={loading}>
                                            {loading ? 'Saving...' : 'Save Events'}
                                        </button>
                                    </Col>
                                </Row>
                            </form>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default CreateEvent
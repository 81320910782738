import React, { useEffect, useRef, useState } from 'react'
import { Alert, Col, Container, Row } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { fetchCategory } from "../../Redux/actions/categoryAction";
import { addProduct } from '../../Redux/actions/productAction';
import { InputLabel, TextField, Autocomplete } from "@mui/material";

const SellWithUs = () => {

    const imageInputRef = useRef(null);
    const pdfInputRef = useRef(null);

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        category: "",
        price: "",
        special_price: "",
        image: null,
        digital_file: null,
        primaryCategory: [],
    });
    const [duplicateError, setDuplicateError] = useState("");
    const [selectedCategories, setSelectedCategories] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { category } = useSelector((state) => state.category);
    const { loading, error, success } = useSelector((state) => state.product);

    useEffect(() => {
        const isLoggedIn = localStorage.getItem('user');
        if (!isLoggedIn) {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        if (success) {
            setFormData({
                name: '',
                description: '',
                category: '',
                price: '',
                special_price: '',
                image: null,
                digital_file: null,
                primaryCategory: [],
            });
            setSelectedCategories([]);
            if (imageInputRef.current) imageInputRef.current.value = '';
            if (pdfInputRef.current) pdfInputRef.current.value = '';
        }
    }, [success]);

    useEffect(() => {
        if (error && error.includes("Duplicate entry")) {
            setDuplicateError("Duplicate entry of name. Please use a unique product name.");
        } else {
            setDuplicateError("");
        }
    }, [error]);

    useEffect(() => {
        dispatch(fetchCategory());
    }, [dispatch]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files ? files[0] : value,
        }));
    };


    const handleCategory = (event, newValue) => {
        setSelectedCategories(newValue);
        setFormData((prevState) => ({
            ...prevState,
            category: newValue ? newValue.slug : "",
        }));
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('slug', formData.name);
        data.append('description', formData.description);
        data.append('category', formData.category);
        data.append('price', formData.price);
        data.append('special_price', formData.special_price);
        data.append('primarycategory', JSON.stringify(formData.primaryCategory));
        if (formData.image) data.append('image', formData.image);
        if (formData.digital_file) data.append('digital_file', formData.digital_file);

        const user = JSON.parse(localStorage.getItem('user'));
        // const userId = user?.id;
        if (user?.id) {
            data.append('user_id', user?.id);
            dispatch(addProduct(data));
        } else {
            console.error('User ID is not available.');
        }
    };

    return (
        <>
            <section>
                <SectionHeader title="Sell with Us" PageLink="Sell with Us"></SectionHeader>
                <Container>
                    <div className="sellwith-main">
                        {loading && <Loading />}
                        {duplicateError && <Alert variant="danger" dismissible>{duplicateError}</Alert>}
                        {error && !duplicateError && <Alert variant="danger" dismissible>{error}</Alert>}
                        {success && <Alert variant="success" dismissible>Your product has been added successfully!</Alert>}
                        <form onSubmit={handleSubmit}>
                            <Row>
                                <div className="sell-card-header">
                                    <h4>General Information</h4>
                                </div>
                                <Col md={12}>
                                    <div>
                                        <label className="form-label" htmlFor="Name">Name <span className='red_star'>*</span></label>
                                        <input type="text" name="name" value={formData.name} id="Name" onChange={handleChange} className="form-control" />
                                    </div>
                                </Col>
                                <Col md={12} className='pt-3'>
                                    <div>
                                        <label className="form-label" htmlFor="Description">Description  <span className='red_star'>*</span></label>
                                        <input type="text" name="description" value={formData.description} id="Description" onChange={handleChange} className="form-control" />
                                    </div>
                                </Col>
                                <Col md={12} className="pt-3">
                                    <InputLabel>
                                        Primary Category <span className="red_star">*</span>
                                    </InputLabel>
                                    <Autocomplete
                                        multiple
                                        options={["Editors Pick", 'Award Winner', 'Indie Authors', 'Author Spotlight', 'Publisher corner', 'Special editions', 'Exclusive content']}
                                        value={formData.primaryCategory}
                                        onChange={(event, newValue) => {
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                primaryCategory: newValue
                                            }));
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Select Primary Categories"
                                            />
                                        )}
                                    />
                                </Col>
                                <Col md={12} className="pt-3">
                                    <InputLabel>Category <span className='red_star'>*</span></InputLabel>
                                    <Autocomplete
                                        options={category.flatMap((cat) =>
                                            [{ label: cat.slug, slug: cat.slug, isParent: true }].concat(
                                                (cat.children || []).map((child) => ({
                                                    label: `${child.slug}`,
                                                    slug: child.slug,
                                                    isParent: false
                                                }))
                                            )
                                        )}
                                        value={selectedCategories}
                                        onChange={handleCategory}
                                        disableCloseOnSelect
                                        loading={loading}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                placeholder="Choose Category"
                                                error={Boolean(error)}
                                                helperText={error ? "Failed to load categories" : ""}
                                            />
                                        )}
                                        getOptionLabel={(option) => option.label || ""}
                                        isOptionEqualToValue={(option, value) => option.slug === value.slug}
                                    />
                                </Col>
                                <Col md={6} className='pt-3'>
                                    <div>
                                        <label className="form-label" htmlFor="Price">Price (USD) <span className='red_star'>*</span></label>
                                        <input type="number" name="price" value={formData.price} id="Price" onChange={handleChange} className="form-control" />
                                    </div>
                                </Col>
                                <Col md={6} className='pt-3'>
                                    <div>
                                        <label className="form-label" htmlFor="SpecialPrice">Special Price (USD)</label>
                                        <input type="number" name="special_price" value={formData.special_price} id="SpecialPrice" onChange={handleChange} className="form-control" />
                                    </div>
                                </Col>
                                <Col md={12} className='pt-3'>
                                    <div>
                                        <label className="form-label" htmlFor="image">Product Image <span className='red_star'>*</span></label>
                                        <input type="file" name="image" onChange={handleChange} accept="image/*" className="form-control" ref={imageInputRef} />
                                    </div>
                                </Col>
                                <Col md={12} className='pt-3'>
                                    <div>
                                        <label className="form-label" htmlFor="digital_file">Product PDF <span className='red_star'>*</span></label>
                                        <input type="file" name="digital_file" onChange={handleChange} accept=".pdf" className="form-control" ref={pdfInputRef} />
                                    </div>
                                </Col>
                                <Col md={12} className='pt-3'>
                                    <div>
                                        <button type="submit" className="btn btn-primary">Save Product</button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </div>
                </Container>
            </section >
        </>
    )
}

export default SellWithUs
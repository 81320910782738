import React, { useEffect, useState } from 'react';
import SectionHeader from '../../components/SectionHeader'
import HeaderImage from '../../images/section-herder-img.jpg'
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { sortByPrice, setItemsPerPage, selectCategory } from '../../Redux/actions/filterActions';
import ProductListing from '../../UI/ProductListing';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router-dom';     

const Shop = () => {
    const [categories, setCategories] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedPriceRange, setSelectedPriceRange] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch();
    const sortBy = useSelector(state => state.shop.sortBy);
    const itemsPerPage = useSelector(state => state.shop.itemsPerPage);
    const pageCount = Math.ceil(productsData.length / itemsPerPage);
    const selectedCategory = useSelector(state => state.shop.selectedCategory);
    const location = useLocation();

    useEffect(() => {
        const fetchCategory = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + '/api/show-category');
                setCategories(response.data);
                console.warn('Error fetching Category:', response.data);

            } catch (error) {
                console.error('Error fetching Category:', error.response);
            }
        };
        fetchCategory();
    }, []);
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + '/api/show-products');
                setProductsData(response.data);

            } catch (error) {
                console.error('Error fetching Category:', error.response);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const query = searchParams.get('search');
        setSearchQuery(query || '');
    }, [location]);

    // Function to sort shop items
    const sortedShop = () => {
        let sortedItems = [...productsData];
        if (sortBy === 'asc') {
            sortedItems.sort((a, b) => parseFloat(a.price.replace('$', '')) - parseFloat(b.price.replace('$', '')));
        } else if (sortBy === 'desc') {
            sortedItems.sort((a, b) => parseFloat(b.price.replace('$', '')) - parseFloat(a.price.replace('$', '')));
        } else if (sortBy === 'A-Z') {
            sortedItems.sort((a, b) => a.slug.localeCompare(b.slug));
        } else if (sortBy === 'Z-A') {
            sortedItems.sort((a, b) => b.slug.localeCompare(a.slug));
        }
        return sortedItems;
    };

    const handlePriceRangeChange = (priceRange) => {
        setSelectedPriceRange(priceRange);
    };

    // Function to filter products based on selected price range
    const filterProductsByPriceRange = (products) => {
        if (!selectedPriceRange) {
            return products;
        }
        // Filter products based on selected price range
        const [minPrice, maxPrice] = selectedPriceRange.split('-').map(price => parseFloat(price.slice(1)));
        return products.filter(product => {
            const productPrice = parseFloat(product.price.replace('$', ''));
            return productPrice >= minPrice && productPrice <= maxPrice;
        });
    };

    const filterProductsByCategory = (products) => {
        if (!selectedCategory) {
            return products;
        }
        return products.filter(product => product.category === selectedCategory);
    };

    const filterProductsBySearchQuery = (products) => {
        if (!searchQuery) {
            return products;
        }
        const searchQueryLowerCase = searchQuery.toLowerCase();
        return products.filter(product =>
            (product.slug && product.slug.toLowerCase().includes(searchQueryLowerCase)) ||
            (product.category && product.category.toLowerCase().includes(searchQueryLowerCase)) ||
            (product.primarycategory && product.primarycategory.toLowerCase().includes(searchQueryLowerCase)) ||
            ((product.brand_id + '').toLowerCase().includes(searchQueryLowerCase))
        );
    };

    const getItemsToDisplay = () => {
        const startIndex = pageNumber * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        const sortedAndFilteredProducts = filterProductsByPriceRange(filterProductsByCategory(sortedShop()));
        const searchFilteredProducts = filterProductsBySearchQuery(sortedAndFilteredProducts);
        return searchFilteredProducts.slice(startIndex, endIndex);
    };

    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(parseInt(value));
        dispatch(setItemsPerPage(parseInt(value)));
    };

    const startItemIndex = pageNumber * itemsPerPage + 1;
    const endItemIndex = Math.min((pageNumber + 1) * itemsPerPage, productsData.length);
    const totalResults = productsData.length;
    return (
        <>
            <SectionHeader title='Shop' image={HeaderImage} PageLink='Shop'></SectionHeader>
            <section className='in-page-container pt-5 pb-5'>
                <div className='product__Section'>
                    <Container>
                        <Row>
                            <Col md={3} sm={4} xs={12}>
                                <div className="sticky-top sticky-content">
                                    <div className="inner__products">
                                        <div class="sort__section mb-3">
                                            <div class="side-head">Sort by</div>
                                            <form>
                                                <select id="rating-filter" class="form-select" onChange={(e) => dispatch(sortByPrice(e.target.value))}>
                                                    <option value="">Default Sorting</option>
                                                    <option value="asc">Low Price</option>
                                                    <option value="desc">High Price</option>
                                                    <option value="A-Z">A-Z</option>
                                                    <option value="Z-A">Z-A</option>
                                                </select>
                                            </form>
                                        </div>
                                        <div className="product__cat__section">
                                            <Accordion flush >
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header >CUSTOMER FAVORITES</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="mb-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">best Seller</label>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header >Subject</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="mb-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Fiction</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Non-Fiction</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Bestsellers</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">New Releases</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Classics</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Children's Books</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Young Adult</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Science Fiction & Fantasy</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Romance</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Mystery & Thriller</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Biographies & Memoirs</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Self-Help</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">Business & Finance</label>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="3">
                                                    <Accordion.Header >Age</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="mb-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">B&amp;N Top 100</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">Teens &amp; YA Top 100</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">Kids Top 100</label>
                                                            </div>
                                                            {/* <div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">NY Times® Bestsellers</label></div><div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">New Releases</label></div><div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">Books by Subject</label> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <Accordion.Item eventKey="4">
                                                    <Accordion.Header >Language</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div class="mb-3">
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-check-checkbox" class="form-check-input" />
                                                                <label title="" for="default-check-checkbox" class="form-check-label">English</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">Chinese</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">Spanish </label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">French</label>
                                                            </div>
                                                            <div class="form-check">
                                                                <input type="checkbox" id="default-checkcheckbox" class="form-check-input" />
                                                                <label title="" for="default-checkcheckbox" class="form-check-label">Arabic</label>
                                                            </div>
                                                            {/* <div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">NY Times® Bestsellers</label></div><div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">New Releases</label></div><div class="form-check"><input type="checkbox" id="default-checkcheckbox" class="form-check-input"><label title="" for="default-checkcheckbox" class="form-check-label">Books by Subject</label> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                        <div className="product__cat__section product_parent_accordion">
                                            <Accordion flush>
                                                {categories
                                                    .filter((parent) =>
                                                        parent.slug === "non-fiction-menu-list" || parent.slug === "fiction-menu-list"
                                                    )
                                                    .map((parent, index) => (
                                                        <Accordion.Item eventKey={index} key={parent.id}>
                                                            <Accordion.Header>{parent.slug}</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className="product_child_accordion">
                                                                    {parent.children && parent.children.length > 0 ? (
                                                                        <Accordion flush>
                                                                            {parent.children.map((child, childIndex) => (
                                                                                <Accordion.Item eventKey={childIndex} key={child.id}>
                                                                                    <Accordion.Header>{child.slug}</Accordion.Header>
                                                                                    <Accordion.Body>
                                                                                        <ul className='pointer'>
                                                                                            {child.children.length > 0 ? (
                                                                                                child.children.map((subChild, subChildIndex) => (
                                                                                                    <li key={subChildIndex}>
                                                                                                        {subChild.slug}
                                                                                                    </li>
                                                                                                ))
                                                                                            ) : (
                                                                                                <li>{child.slug}</li>
                                                                                            )}
                                                                                        </ul>
                                                                                    </Accordion.Body>
                                                                                </Accordion.Item>
                                                                            ))}
                                                                        </Accordion>
                                                                    ) : (
                                                                        <ul><li>{parent.slug}</li></ul>
                                                                    )}
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="category">
                                    <h5 className="price-range">PRICE RANGE</h5>
                                    <ul className="price__range">
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="$5-$10"
                                                onChange={() => handlePriceRangeChange("$5-$10")}
                                            />
                                            <div className='price-span'>
                                                <span>$5</span>
                                                -
                                                <span>$10</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="$10-$15"
                                                onChange={() => handlePriceRangeChange("$10-$15")}
                                            />
                                            <div className='price-span'>
                                                <span>$10</span>
                                                -
                                                <span>$15</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="$15-$25"
                                                onChange={() => handlePriceRangeChange("$15-$25")}
                                            />
                                            <div className='price-span'>
                                                <span>$15</span>
                                                -
                                                <span>$25</span>
                                            </div>
                                        </li>
                                        <li>
                                            <input
                                                type="checkbox"
                                                name="$25-$50"
                                                onChange={() => handlePriceRangeChange("$25-$50")}
                                            />
                                            <div className='price-span'>
                                                <span>$25</span>
                                                -
                                                <span>$50</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div> */}
                            </Col>
                            <Col md={9} sm={8} xs={12}>
                                {/* <div className='sort-by'>
                                    <div className="sort-by-form-group">
                                        <form className='sort-by-form-2'>
                                            <select id="itemsPerPage" onChange={(e) => handleItemsPerPageChange(e.target.value)}>
                                                <option value="9">Default Show</option>
                                                <option value="12">12</option>
                                                <option value="25">25</option>
                                                <option value="45">45</option>
                                                <option value="50">50</option>
                                            </select>
                                        </form>
                                    </div>
                                    <div className='page-show-span'>
                                        Showing
                                        <span>{startItemIndex} to {endItemIndex}</span> of
                                        <span>{totalResults}</span> results
                                    </div>
                                </div> */}
                                <div className='pb-5'>
                                    {getItemsToDisplay().length > 0 ? (
                                        <Row>
                                            <ProductListing sortedProducts={getItemsToDisplay()} />
                                        </Row>
                                    ) : (
                                        <div className="alert alert-warning" role="alert">
                                            No products found.
                                        </div>
                                    )}
                                </div>
                                <ReactPaginate className='d-none'
                                    nextLabel="next"
                                    onPageChange={handlePageChange}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={pageCount}
                                    previousLabel="previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakLabel="..."
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section >
        </>
    )
}
export default Shop

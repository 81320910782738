import React, { useEffect, useState } from 'react';
import SectionHeader from '../../components/SectionHeader';
import { Container } from 'react-bootstrap';

const MultilingualLanguages = () => {
    const languages = [
        { code: 'en', label: 'English', flag: '🇺🇸' },
        { code: 'ar', label: 'Arabic', flag: '🇸🇦' },
        { code: 'fr', label: 'French', flag: '🇫🇷' },
        { code: 'hi', label: 'Hindi', flag: '🇮🇳' },
        { code: 'ja', label: 'Japanese', flag: '🇯🇵' },
        { code: 'pt', label: 'Portuguese', flag: '🇵🇹' },
        { code: 'pa', label: 'Punjabi', flag: '🇮🇳' },
        { code: 'ru', label: 'Russian', flag: '🇷🇺' },
        { code: 'es', label: 'Spanish', flag: '🇪🇸' },
        { code: 'zh-CN', label: 'Chinese', flag: '🇨🇳' },
    ];
    const [isGoogleTranslateLoaded, setIsGoogleTranslateLoaded] = useState(false);
    useEffect(() => {
        const googleTranslateElementInit = () => {
            try {
                new window.google.translate.TranslateElement(
                    {
                        pageLanguage: 'en',
                        includedLanguages: 'en,ar,fr,hi,ja,pt,pa,ru,es,zh-CN',
                        autoDisplay: false,
                    },
                    'google_translate_element'
                );
                setIsGoogleTranslateLoaded(true);
            } catch (error) {
                console.error('Google Translate initialization failed:', error);
                setIsGoogleTranslateLoaded(false);
            }
        };

        const loadGoogleTranslateScript = () => {
            if (!document.querySelector('#google-translate-script')) {
                const script = document.createElement('script');
                script.id = 'google-translate-script';
                script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                script.async = true;
                script.onload = () => setIsGoogleTranslateLoaded(true);
                script.onerror = () => {
                    console.error('Failed to load Google Translate script.');
                    setIsGoogleTranslateLoaded(false);
                };
                document.body.appendChild(script);
                window.googleTranslateElementInit = googleTranslateElementInit;
            }
        };
        loadGoogleTranslateScript();
    }, []);

    const handleLanguageChange = (langCode) => {
        if (!isGoogleTranslateLoaded) {
            console.warn('Google Translate script is not fully loaded yet.');
            return;
        }
        const googleTranslateSelect = document.querySelector('.goog-te-combo');
        if (googleTranslateSelect) {
            googleTranslateSelect.value = langCode;
            googleTranslateSelect.dispatchEvent(new Event('change')); // Trigger the dropdown change
        } else {
            console.warn('Google Translate dropdown is not available.');
        }
    };

    return (
        <section>
            <SectionHeader title="Multilingual Languages" PageLink="Multilingual Languages" />
            <Container>
                <div className="multi-language-main">
                    <h2>Please Select Your Language</h2>
                    <div className="multi-language">
                        {languages.map((lang) => (
                            <button
                                key={lang.code}
                                className="language-button"
                                onClick={() => handleLanguageChange(lang.code)}
                                disabled={!isGoogleTranslateLoaded} 
                            >
                                {/* <span className="language-flag">{lang.flag}</span> */}
                                <span className="language-label">{lang.label}</span>
                            </button>
                        ))}
                    </div>
                </div>
                <div id="google_translate_element" style={{ display: 'none' }}></div>
            </Container>
        </section>
    );
};

export default MultilingualLanguages;

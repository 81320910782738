import React, { useCallback, useEffect, useState } from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { IoCallSharp } from "react-icons/io5";
import { IoSearch } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { GrUserManager } from "react-icons/gr";
import { FaCartShopping } from "react-icons/fa6";
import { RiArrowRightSLine, RiLoginBoxLine } from "react-icons/ri";
import { fetchSettings } from '../Redux/actions/settingsActions';
import { useDispatch, useSelector } from 'react-redux';
import { DLT } from '../Redux/actions/CartAction';
import Badge from '@mui/material/Badge';
import CartBody from './CartBody';

const HeaderNav = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();
    const [price, setPrice] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const getdata = useSelector((state) => state.cartreducer.carts);
    const isLoggedIn = useSelector(state => state.auth.user);
    const userData = JSON.parse(localStorage.getItem('user-info'));
    const isAuthor = userData && userData.user_type === "author";
    // ************ CMS START ************
    const { settings } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const phone_link = settings.find(setting => setting.name === 'phone_link');
    const phone_text = settings.find(setting => setting.name === 'phone_text');
    // For image 
    const mid_header_logo = settings.find(setting => setting.name === 'mid_header_logo');
    const baseImageUrl = process.env.REACT_APP_BASE_URL + '/public/uploads/media/';
    const imageUrl = mid_header_logo?.value ? `${baseImageUrl}${mid_header_logo.value}` : '';
    // ************ CMS END ************

    // var settingss = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     arrows: false,
    // };
    const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleSearch = (event) => {
        event.preventDefault();
        navigate(`/shop?search=${searchQuery}`);
    };
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dlt = (id) => {
        dispatch(DLT(id))
    }
    const total = useCallback(() => {
        let totalPrice = 0;
        getdata.forEach((ele, k) => {
            totalPrice = ele.price * ele.qnty + totalPrice;
        });
        setPrice(totalPrice);
    }, [getdata]);
    useEffect(() => {
        total();
    }, [total])

    return (
        <>
            <div className="mid-bar">
                <Container>
                    <Row className='align-items-center'>
                        <Col lg={3} md={2} sm={2} xs={4} className='d-flex justify-content-center'>
                            <Link to="/" className='logo'>
                                <img src={imageUrl} alt="" />
                            </Link>
                        </Col>
                        <Col lg={9} md={10} sm={10} xs={8}>
                            <div className="right-side">
                                <div className="h-searchbox">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search entire store here..." />
                                        <button type="button" id="search-bar" className="btn btn-outline-secondary"><IoSearch /></button>
                                    </div>
                                </div>
                                {/* <form onSubmit={handleSearch} className='searchbar'>
                                    <InputGroup className="mb-3 ">
                                        <Form.Control
                                            placeholder="Search All Products"
                                            value={searchQuery}
                                            onChange={handleInputChange}
                                        />
                                        <button type='submit'>sdf</button>
                                    </InputGroup>
                                </form> */}
                                <div className="h-callinfo">
                                    <ul>
                                        <li className="icon-area"><span><IoCallSharp /></span></li>
                                        <li>
                                            <span>Call Us For More Info </span><br />
                                            <Link to={phone_link?.value}> {phone_text?.value}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="h-wishlist">
                                    <ul>
                                        {/* <li className="icon-area"><span><FaRegHeart /></span></li> */}
                                        <li><Link to="/wishlist"><i className='header_input_icon'><FaRegHeart /></i></Link></li>
                                    </ul>
                                </div>
                                <div className="h-cart my_account">
                                    {isLoggedIn ? (
                                        <NavLink to='dashboard/overview'>
                                            <i className='header_input_icon'><GrUserManager /></i>
                                        </NavLink>
                                    ) : (
                                        <NavLink to='/login'>
                                            <i className='header_input_icon'><RiLoginBoxLine /></i>
                                        </NavLink>
                                    )}
                                </div>
                                <div className="h-cart">
                                    <NavLink to='#'>
                                        <Badge badgeContent={getdata.length} color="primary"
                                            id="basic-button"
                                            aria-controls={open ? 'basic-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                            onClick={handleClick}
                                        >
                                            <i className='header_input_icon' ><FaCartShopping /></i>
                                        </Badge>
                                    </NavLink>
                                </div>
                                {/* <div className="h-user">
                                    <NavDropdown title={
                                        <div className="pull-left">
                                            <img className="thumbnail-image" src={User} alt="user pic" />
                                        </div>
                                    } id="basic-nav-dropdown">
                                        <NavDropdown.Item as={Link} to="#">Profile</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="#">Settings</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to="#">Logout</NavDropdown.Item>
                                    </NavDropdown>
                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CartBody anchorEl={anchorEl} open={open} handleClose={handleClose} getdata={getdata} dlt={dlt} price={price} />
            <div className="nav-bar">
                <Navbar expand="lg" className="nav_bar" data-bs-theme="light">
                    <Container>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <div className='navbar-nav' >
                                <NavLink to="/"
                                    className='simple_nav nav-link'>Home
                                </NavLink>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="browse-books-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Browse Books
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="browse-books-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Browse books <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Ebooks</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Fiction">Fiction</Link></li>
                                                                <li><Link to="/shop?search=Non-Fiction">Non-Fiction</Link></li>
                                                                <li><Link to="/shop?search=Bestsellers">Bestsellers</Link></li>
                                                                <li><Link to="/shop?search=New Releases">New Releases</Link></li>
                                                                <li><Link to="/shop?search=Classics">Classics</Link></li>
                                                                <li><Link to="/shop?search=Children's Books">Children's Books</Link></li>
                                                                <li><Link to="/shop?search=Young Adult">Young Adult</Link></li>
                                                                <li><Link to="/shop?search=Science Fiction & Fantasy">Science Fiction & Fantasy</Link></li>
                                                                <li><Link to="/shop?search=Romance">Romance</Link></li>
                                                                <li><Link to="/shop?search=Mystery & Thriller">Mystery & Thriller</Link></li>
                                                                <li><Link to="/shop?search=Biographies & Memoirs">Biographies & Memoirs</Link></li>
                                                                <li><Link to="/shop?search=Self-Help">Self-Help</Link></li>
                                                                <li><Link to="/shop?search=Business & Finance">Business & Finance</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Audiobooks</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Audiobooks-Fiction">Fiction</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Non-Fiction">Non-Fiction</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Bestsellers">Bestsellers</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-New Releases">New Releases</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Short Stories">Short Stories</Link></li>
                                                                <li><Link to="/shop?search=Audiobooks-Podcasts & Interviews">Podcasts & Interviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>- Languages</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/multilingual-languages">Multilingual Options</Link></li>
                                                                <li><Link to="/language-learning">Language Learning</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Genres</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=">Explore All Genres</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="discover-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Discover
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="discover-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Discover <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Curated Collections</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Editors Pick">Editor’s Picks</Link></li>
                                                                <li><Link to="/shop?search=Award Winner">Award Winners</Link></li>
                                                                <li><Link to="/shop?search=Indie Authors">Indie Authors</Link></li>
                                                                <li><Link to="/shop?search=Trending Now">Trending Now</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Spotlight</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Author Spotlight">Author Spotlight</Link></li>
                                                                <li><Link to="/author-corner">Author's Corner </Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Publisher’s Corner</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Publisher corner">Publisher’s Corner</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Special Editions</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Special Editions">Special Editions</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Exclusive Content</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/shop?search=Exclusive Content">Exclusive Content</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="discover-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Community
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="community-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Community <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Book Clubs</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/start-club">Start a Club</Link></li>
                                                                <li><Link to="/join-club">Join a Club</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Reader Reviews</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/reader-reviews">Reader Reviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Discussion Forums</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/discussion-forums">Discussion Forums</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Events</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/author-events">Author Events</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Interviews & Podcasts</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/interviews-podcasts">Interviews & Podcasts</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="authors-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className={`dropdown-toggle nav-link ${isAuthor ? '' : 'disabled'}`}
                                        tabIndex={isAuthor ? 0 : -1}>For Authors & Publishers
                                    </NavLink>
                                    <div className={`dropdown-menu ${isAuthor ? '' : 'd-none'}`} data-bs-popper="static" aria-labelledby="authors-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        For Authors & Publishers <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Sell with Us</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/sell-with-us">Sell with Us</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    {/* <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Author Tools & Resources</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/author-tools-resources">Author Tools & Resources</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col> */}
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Publisher Dashboard</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/publisher-dashboard">Publisher Dashboard</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Royalties & Payments</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/user-payment">Royalties & Payments</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <div className="menu-box-heading">
                                                                <h6>Marketing & Promotion Services</h6>
                                                            </div>
                                                            <ul>
                                                                <li><Link to="/marketing-promotion-services">Marketing & Promotion Services</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="about"
                                        data-mdb-button-init
                                        type="button"
                                        id="about-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>About Us
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="about-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        About us <RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/our-story">Our Story</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/mission-vision">Mission & Vision</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/press-media">Press & Media</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/careers">Careers</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/privacy">Privacy Policy</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/termsConditions">Terms & Conditions</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="blogs"
                                        data-mdb-button-init
                                        type="button"
                                        id="blog-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Blog
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="blog-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Blog<RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Reading Lists">Reading Lists</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Book Reviews">Book Reviews</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Industry News">Industry News</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Tips for Readers">Tips for Readers</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Digital Reading Guides">Digital Reading Guides</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={3}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/blogs?type=Meet Authors">Meet Authors</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div className='nav-item dropdown' >
                                    <NavLink to="#"
                                        data-mdb-button-init
                                        type="button"
                                        id="support-nav-dropdown"
                                        data-mdb-toggle="dropdown"
                                        aria-expanded="false"
                                        data-mdb-ripple-init data-mdb-dropdown-init
                                        className='dropdown-toggle nav-link'>Support
                                    </NavLink>
                                    <div className="dropdown-menu" data-bs-popper="static" aria-labelledby="support-nav-dropdown">
                                        <Row>
                                            <Col md={12}>
                                                <h4 className="nav-heading">
                                                    <Link to="#">
                                                        Support<RiArrowRightSLine />
                                                    </Link>
                                                </h4>
                                            </Col>
                                            <Col md={12}>
                                                <Row>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/customer-service">Customer Service</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/faqs">FAQs</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/technical-support">Technical Support</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/order-search">Order Tracking</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">

                                                            <ul>
                                                                <li><Link to="/refund">Return & Refund Policy</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="menu-box">
                                                            <ul>
                                                                <li><Link to="/contact">Contact Us</Link></li>
                                                            </ul>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div >
            {/* <Navbar expand="lg" className="nav_bar" data-bs-theme="dark">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <div className='row' >

                        <div className="header_input">
                            <form onSubmit={handleSearch} className='searchbar'>
                                <InputGroup className="mb-3 ">
                                    <Form.Control
                                        placeholder="Search All Products"
                                        value={searchQuery}
                                        onChange={handleInputChange}
                                    />
                                    <button type='submit'><CiSearch /></button>
                                </InputGroup>
                            </form>
                            {isLoggedIn ? (
                                <NavLink to='dashboard/overview'>
                                    <i className='header_input_icon'><GrUserManager /></i>
                                </NavLink>
                            ) : (
                                <NavLink to='/login'>
                                    <i className='header_input_icon'><RiLoginBoxLine /></i>
                                </NavLink>
                            )}
                            <NavLink to='#'>
                                <Badge badgeContent={getdata.length} color="primary"
                                    id="basic-button"
                                    aria-controls={open ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                >
                                    <i className='header_input_icon' ><CgShoppingBag /></i>
                                </Badge>
                            </NavLink>
                        </div>
                    </div>
                </Navbar.Collapse>
            </Navbar> */}
        </>
    )
}

export default HeaderNav;

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios';
import SectionHeader from '../../components/SectionHeader';
import NoImage from "../../images/NoImage.jpg";
import { FaUser } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
import Loading from '../../components/Loading';
const AuthorBlogDetails = () => {
    const { userid } = useParams();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    let baseUrl = process.env.REACT_APP_BASE_URL;

    useEffect(() => {
        console.log('User ID from URL:', userid);

        axios.get(`${baseUrl}/api/show-author-blogs`)
            .then(response => {
                const userBlogs = response.data.filter(blog => blog.userid === userid);
                // console.log('Filtered Blogs:', userBlogs);
                if (userBlogs.length === 0) {
                    setError('No blogs found for this user.');
                } else {
                    setBlogs(userBlogs);
                }
            })
            .catch(error => {
                setError('Error fetching blogs.');
                console.error('Error fetching user blogs:', error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [userid, baseUrl]);

    return (
        <>
            <SectionHeader title="Author Corner" PageLink="Author Corner"></SectionHeader>
            {loading && <p>Loading...</p>}
            {error && <p className="text-danger">{error}</p>}
            <section className="blog-bg">
                <Container className='blog-post'>
                    <div className="blog-posts" data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="linear">
                        {blogs.length > 0 ? (
                            blogs.map(blog => (
                                <Card className='blog_card' key={blog.id}>
                                    {blog.blog_images ? (
                                        <Card.Img className="blog_card_img" variant="top"
                                            src={`${baseUrl}/storage/app/${blog.blog_images}`}
                                        />
                                    ) : (
                                        <Card.Img className="blog_card_img" variant="top" src={NoImage} alt="Product img" />
                                    )}
                                    <Card.Body className='blog_text'>
                                        <div className="date_admin">
                                            <Link to="#">
                                                <p><span><IoCalendarOutline /></span>{blog.date}</p>
                                            </Link>
                                            <Link to="#">
                                                <p><span><FaUser /></span> Admin</p>
                                            </Link>
                                        </div>
                                        <p className="bolg_text">
                                            {/* <h4>{blog.title}</h4> */}
                                            <Link to={`/single-author-blog/${blog.id}`}><h4>{blog.title}</h4></Link>
                                            {/* <p>{blog.type}</p> */}
                                            <div className='blog__content' dangerouslySetInnerHTML={{ __html: blog.description }} />
                                        </p>
                                    </Card.Body>
                                </Card>
                            ))
                        ) : (
                            <p>No blogs to display.</p>
                        )}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default AuthorBlogDetails;

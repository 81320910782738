import React from 'react'
import SectionHeader from '../../components/SectionHeader';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const LanguageLearning = () => {
    const languages = [
        { label: 'English'},
        { label: 'Arabic'},
        { label: 'French'},
        { label: 'Hindi'},
        { label: 'Japanese'},
        { label: 'Portuguese'},
        { label: 'Punjabi'},
        { label: 'Russian'},
        { label: 'Spanish'},
        { label: 'Chinese'},
    ];
    return (
        <>
            <section>
                <SectionHeader title="Language Learning" PageLink="Language Learning" />
                <Container>
                    <div className="multi-language-main">
                        <div className="multi-language">
                            {languages.map((lang) => (
                                <button  >
                                    <Link  to={"/en-to/" + lang.label} className="language-button">{lang.label}</Link>
                                </button>
                            ))}
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default LanguageLearning
import React from 'react';
import SectionHeader from '../../components/SectionHeader';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UserType = () => {
    return (
        <>
            <SectionHeader title="Select User Type" PageLink="Select User Type"></SectionHeader>
            <section>
                <Container>
                    <div className="user_types">
                        <div className="user_type_item">
                            <Link
                                to={{ pathname: '/register', }}
                                state={{ user_type: 'author' }} >
                                Register for Author
                            </Link>
                        </div>
                        <div className="user_type_item">
                            <Link
                                to={{ pathname: '/register', }}
                                state={{ user_type: 'user' }} >
                                Register for User
                            </Link>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default UserType;

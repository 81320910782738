import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/SectionHeader'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

const AuthorCorner = () => {
    const [uniqueUsers, setUniqueUsers] = useState([]);
    let baseUrl = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        axios.get(baseUrl + '/api/show-author-blogs')
            .then(response => {
                const users = [...new Set(response.data.map(blog => blog.userid))];
                setUniqueUsers(users);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }, [baseUrl]);
    return (
        <>
            <SectionHeader title="Author Corner" PageLink="Author Corner"></SectionHeader>
            <Container>
                <div className="multi-language-main">
                    <div className="multi-language">
                        {uniqueUsers.map((userid) => (
                            <button  key={userid}>
                                <Link to={`/author-blog-details/${userid}`} className="language-button">
                                    {userid}
                                </Link>
                            </button>
                        ))}
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AuthorCorner
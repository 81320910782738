import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import SectionHeader from '../../components/SectionHeader';
import Loading from '../../components/Loading';
import axios from 'axios';
import { FaStar } from "react-icons/fa";
import { FaStarHalfStroke } from "react-icons/fa6";
import { FaRegStar } from "react-icons/fa6";

const ReaderReviews = () => {
    const { id } = useParams();
    const [reviews, setReviews] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const fetchAuthorEvents = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/show-reviews`);
                setReviews(response.data);
            } catch (error) {
                console.error('Error fetching event details!', error);
            } finally {
                setLoading(false);
            }
        };
        fetchAuthorEvents();
    }, [id]);

    const renderStars = (rating) => {
        const starComponents = [];
        const wholeStars = Math.floor(rating);
        const decimalPart = rating - wholeStars;
        // Whole stars
        for (let i = 0; i < wholeStars; i++) { starComponents.push(<FaStar key={i} />); }
        // Half-filled star
        if (decimalPart >= 0.5) { starComponents.push(<FaStarHalfStroke key="half" />); }
        // Empty stars
        const remainingStars = 5 - Math.ceil(rating);
        for (let i = 0; i < remainingStars; i++) {
            starComponents.push(<FaRegStar key={`empty-${i}`} style={{ color: "#818286" }} />);
        }
        return starComponents;
    };

    return (
        <>
            <section className='reader-reviews'>
                <SectionHeader title='Reader Reviews' PageLink="Reader Reviews"></SectionHeader>
                <div className='pt-5 pb-5'>
                    <Container>
                        <Row>
                            {loading && <Loading />}
                            {!loading && reviews.length === 0 && (
                                <p>No reviews available.</p>
                            )}
                            {reviews.map(({ id, rating, comment, created_at, user, product }) => (
                                <Col xxl={3} xl={4} md={4} sm={6} xs={12} key={id}>
                                    <div className="reader-reviews-main">
                                        <div className="reader-reviews-header">
                                            <h5>{user.name}</h5>
                                            <div className='rating__pro'>
                                                <p>{new Date(created_at).toDateString()}</p>
                                                {rating ? (
                                                    renderStars(rating)
                                                ) : (
                                                    Array.from({ length: 5 }, (_, index) => <FaRegStar key={index} />)
                                                )}
                                            </div>
                                        </div>
                                        <div className="reader-reviews-body">

                                            <Link to={"/single-product/" + product.id}><strong>Product:</strong> {product.slug}</Link>
                                            <p>{comment}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default ReaderReviews
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Alert } from 'react-bootstrap';
import Loading from '../../components/Loading';
import { useNavigate } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader';

const Careers = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const imageInputRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userid: '',
        name: '',
        email: '',
        phone: '',
        address: '',
        position: '',
        coverLetter: '',
        skills: '',
        experience: '',
        resume: null,
    });

    return (
        <>
            <section className="careers">
                <SectionHeader title="Careers" PageLink="Careers"></SectionHeader>
                <Container>
                    <div className="sellwith-main">
                        {loading && <Loading />}
                        {showAlert && (<Alert variant={alertType === 'success' ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible > {alertMessage} </Alert>)}

                        <form>
                            <Row>
                                <div class="sell-card-header"><h4>Careers Information</h4></div>
                                <Col md={6} className="mb-3">
                                    <label className="form-label" htmlFor="name">Name<span className='red_star'>*</span></label>
                                    <input type="text" name="name" id='name' value={formData.name} className="form-control" required />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <label className="form-label" htmlFor="email">Email<span className='red_star'>*</span></label>
                                    <input type="email" id='email' name="email" value={formData.email} className="form-control" required />
                                </Col>
                                <Col md={6} className="mb-3">
                                    <label className="form-label" htmlFor="phone">Phone<span className='red_star'>*</span></label>
                                    <input type="text" id='phone' name="phone" value={formData.phone} className="form-control" required />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <label className="form-label" htmlFor="address">Address<span className='red_star'>*</span></label>
                                    <input type="text" id='address' name="address" value={formData.address} className="form-control" required />
                                </Col>
                                <Col md={12} className="mb-3">
                                    <label className="form-label" htmlFor="coverLetter">Cover Letter<span className='red_star'>*</span></label>
                                    <textarea id="coverLetter" name="coverLetter" value={formData.coverLetter} className="form-control" rows="3" required />
                                </Col>
                                <Col md={12} className="mb-3">
                                    <label className="form-label" htmlFor="skills">Skills<span className='red_star'>*</span></label>
                                    <textarea id="skills" name="skills" value={formData.skills} className="form-control" rows="3" required />
                                </Col>
                                <Col md={12} className="mb-3">
                                    <label className="form-label" htmlFor="experience">Experience<span className='red_star'>*</span></label>
                                    <textarea id="experience" name="experience" value={formData.experience} className="form-control" rows="3" required />
                                </Col>
                                <Col md={6} className='mb-3'>
                                    <label className="form-label" htmlFor="resume">Resume<span className='red_star'>*</span></label>
                                    <input type="file" id='resume' name="thumbnail"  accept=".pdf,.doc,.docx" className="form-control" />
                                </Col>
                                <button type="submit" className="blue-btn btn-style round-btn btn btn-link" disabled={loading}>
                                    {loading ? 'Submitting...' : 'Submit'}
                                </button>
                            </Row>
                        </form>
                    </div>
                </Container>
            </section>
        </>
    )
}

export default Careers

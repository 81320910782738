import React from 'react';
import SectionHeader from '../../components/SectionHeader';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

const LanguageConverter = () => {
    const { label } = useParams();

    const languageData = {
        English: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text." },
        Arabic: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "هناك العديد من الأشكال المتوفرة لنصوص لوريم إيبسوم، لكن الأغلبية قد عانت من التغيير بشكل ما، عن طريق إدخال الفكاهة أو الكلمات العشوائية التي لا تبدو قابلة للتصديق ولو قليلاً. إذا كنت ستستخدم فقرة من نص لوريم إيبسوم، فيجب أن تتأكد من عدم وجود أي شيء محرج مخفي في منتصف النص." },
        French: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "Il existe de nombreuses variantes de passages du Lorem Ipsum disponibles, mais la majorité ont subi des altérations sous une forme ou une autre, par injection d'humour ou par des mots aléatoires qui ne semblent même pas légèrement crédibles. Si vous comptez utiliser un passage du Lorem Ipsum, vous devez vous assurer qu'il n'y a rien d'embarrassant caché au milieu du texte." },
        Hindi: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "लोरेम इप्सम के अंशों की कई विविधताएँ उपलब्ध हैं, लेकिन अधिकांश को किसी न किसी रूप में, हास्य-विनोद या यादृच्छिक शब्दों के माध्यम से परिवर्तन का सामना करना पड़ा है, जो थोड़ा सा भी विश्वसनीय नहीं लगता है। यदि आप लोरेम इप्सम के एक अंश का उपयोग करने जा रहे हैं, तो आपको यह सुनिश्चित करना होगा कि पाठ के बीच में कुछ भी शर्मनाक नहीं छिपा है।" },
        Japanese: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "『ローレム・イプサム』の一節にはさまざまなバリエーションが存在しますが、その大部分は、注入されたユーモアや、少しでも信じられないようなランダム化された言葉によって、何らかの形で改変を受けています。 Lorem Ipsum の一節を使用する場合は、テキストの途中に恥ずかしいものが隠されていないことを確認する必要があります。" },
        Portuguese: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "Existem muitas variações de passagens de Lorem Ipsum disponíveis, mas a maioria sofreu alterações de alguma forma, por injeção de humor ou palavras aleatórias que não parecem nem um pouco críveis. Se você for usar uma passagem de Lorem Ipsum, você precisa ter certeza de que não há nada embaraçoso escondido no meio do texto." },
        Punjabi: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "لورم اپسم دے حوالےآں دی بہت ساریاں قسماں دستیاب ہن، لیکن اکثریت نے کسی نہ کسی شکل وچ تبدیلی دا سامنا کیتا اے، انجیکشن شدہ مزاح، یا بے ترتیب الفاظ دے ذریعہ جو تھوڑا سا وی قابل اعتماد نئیں لگدے۔ جے تسی لورم اپسم دا کوئی حوالہ استعمال کرن جا رئے او، تے تہانوں ایہ یقینی بنان دی لوڑ اے کہ متن دے وچکار کوئی شرمناک گل نئیں لُکی ہوئی اے۔" },
        Russian: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "Существует множество вариаций отрывков Lorem Ipsum, но большинство из них претерпело изменения в той или иной форме из-за привнесенного юмора или случайных слов, которые не выглядят даже немного правдоподобно. Если вы собираетесь использовать отрывок из Lorem Ipsum, вам нужно быть уверенным, что в середине текста не спрятано ничего смущающего." },
        Spanish: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "Hay muchas variaciones de pasajes de Lorem Ipsum disponibles, pero la mayoría ha sufrido alguna alteración, mediante humor inyectado o palabras aleatorias que no parecen ni un poco creíbles. Si vas a utilizar un pasaje de Lorem Ipsum, debes asegurarte de que no haya nada vergonzoso escondido en medio del texto." },
        Chinese: { english: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.", other: "《Lorem Ipsum》的段落有很多變體，但大多數都經過某種形式的改變，透過注入幽默，或隨機的單詞，看起來甚至不太可信。如果您要使用 Lorem Ipsum 的一段文字，您需要確保文字中間沒有隱藏任何令人尷尬的內容。" },
    };

    const selectedLanguage = languageData[label];

    return (
        <>
            <section>
                <SectionHeader title={`${label} Language`} PageLink={`${label} Language`} />
                <Container>
                    <div className="multi-language-main">
                        {selectedLanguage ? (
                            <>
                                <div className="language-convert-main">
                                    <div className='language-convert'>
                                        <h2>English</h2>
                                        <p>{selectedLanguage.english}</p>
                                    </div>
                                    <div className='language-convert right'>
                                        <h2>{label}</h2>
                                        <p>{selectedLanguage.other}</p>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <p>Language not found!</p>
                        )}
                    </div>
                </Container>
            </section>
        </>
    );
};

export default LanguageConverter;

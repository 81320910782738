import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Container, Row, Col, Card, Alert } from 'react-bootstrap';
import Loading from '../../../components/Loading';
import { useNavigate } from 'react-router-dom';

const InterviewPodcast = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertMessage, setAlertMessage] = useState('');
    const imageInputRef = useRef(null);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        userid: '',
        title: '',
        date: '',
        description: '',
        digital_file: '',
        thumbnail: null,
    });


    useEffect(() => {
        const user = localStorage.getItem('user');
        if (!user) {
            navigate('/login');
        } else {
            try {
                const parsedUser = JSON.parse(user);
                setFormData((prevState) => ({
                    ...prevState,
                    userid: parsedUser.name,
                }));
            } catch (err) {
                console.error('Error parsing user data from localStorage:', err);
                navigate('/login');
            }
        }
    }, [navigate]);

    const handleCshange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: files ? files[0] : value,
        }));
    };
    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "thumbnail") {
            setFormData((prevState) => ({
                ...prevState,
                [name]: files[0],
            }));
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setShowAlert(false);

        try {
            const data = new FormData();
            data.append('userid', formData.userid);
            data.append('title', formData.title);
            data.append('date', formData.date);
            data.append('description', formData.description);
            data.append('digital_file', formData.digital_file);
            if (formData.thumbnail) {
                data.append('thumbnail', formData.thumbnail);
            }

            let baseUrl = process.env.REACT_APP_BASE_URL;
            const response = await axios.post(baseUrl + '/api/interview-store', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setAlertType('success');
            setAlertMessage('Interviews & Podcasts stored successfully!');
            setShowAlert(true);
            // Reset the form after success
            setFormData({
                userid: formData.userid,
                title: '',
                date: '',
                description: '',
                digital_file: '',
                thumbnail: null,
            });
            // Clear the file input
            if (imageInputRef.current) {
                imageInputRef.current.value = '';
            }
            setTimeout(() => setShowAlert(false), 3000);

        } catch (err) {
            setAlertType('error');
            setAlertMessage('Failed to store Interviews & Podcasts. Please try again.');
            setShowAlert(true);
            setTimeout(() => setShowAlert(false), 3000);
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <>
            <div className="dash-card">
                <Card className="dash-sub-card">
                    <Card.Header as="h4" className="dash-card-header">Interviews & Podcasts</Card.Header>
                    <Card.Body>
                        {loading && <Loading />}
                        {showAlert && ( <Alert variant={alertType === 'success' ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible > {alertMessage} </Alert>)}
                        <Container>
                            <form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6} className="mb-3">
                                        <label className="form-label" htmlFor="title">Title<span className='red_star'>*</span></label>
                                        <input type="text" name="title" id='title' value={formData.title} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={6} className="mb-3">
                                        <label className="form-label" htmlFor="date">Date<span className='red_star'>*</span></label>
                                        <input type="date" id='date' name="date" value={formData.date} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={12} className="mb-3">
                                        <label className="form-label" htmlFor="description">Description<span className='red_star'>*</span></label>
                                        <textarea id="description" name="description" value={formData.description} onChange={handleChange} className="form-control" rows="4" required />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <label className="form-label" htmlFor="digital_file">Video Link<span className='red_star'>*</span></label>
                                        <input type="text" id='digital_file' name="digital_file" value={formData.digital_file} onChange={handleChange} className="form-control" required />
                                    </Col>
                                    <Col md={6} className='mb-3'>
                                        <label className="form-label" htmlFor="thumbnail">Thumbnail<span className='red_star'>*</span></label>
                                        <input type="file" id='thumbnail' name="thumbnail" onChange={handleChange} accept="image/*" className="form-control" ref={imageInputRef} />
                                    </Col>
                                    <button type="submit" className="blue-btn btn-style round-btn btn btn-link" disabled={loading}>
                                        {loading ? 'Saving...' : 'Save'}
                                    </button>
                                </Row>
                            </form>
                        </Container>
                    </Card.Body>
                </Card>
            </div>
        </>
    )
}

export default InterviewPodcast
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { IoMdStar } from "react-icons/io";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSettings } from '../Redux/actions/settingsActions';
import axios from 'axios';
import Img2 from '../images/dealimg02.png'
const FeaturedProducts = () => {
    const [product, setProduct] = useState([]);
    // ******* CMS START ******* 
    const dispatch = useDispatch();
    const { settings } = useSelector(state => state.settings);
    useEffect(() => {
        dispatch(fetchSettings());
    }, [dispatch]);
    const section_heading_2 = settings.find(setting => setting.name === 'section_heading_2');
    // ******* CMS END ******* 
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                let baseUrl = process.env.REACT_APP_BASE_URL;
                const response = await axios.get(baseUrl + '/api/show-products');
                const featuredProducts = response.data.filter(
                    product => product.featured_tag === 'featured'
                );
                setProduct(featuredProducts);
            } catch (error) {
                console.error('Error fetching Featured product:', error);
            }
        };
        fetchProduct();
    }, []);
    return (
        <>
            <section>
                <div className="fea-products">
                    <Container>
                        <h2 className="head-sec">{section_heading_2?.value}</h2>
                        <div className="listing">
                            <Row className='justify-content-center'>
                                {product.map((product) => {
                                    const cleanSlug = product.slug.replace(/-/g, " ");
                                    const cleanedUrl = product.imagestwo
                                        .replace(/[\\[\]"]/g, '')
                                        .split(',')
                                        .map(url =>
                                            url.includes('/storage/app/')
                                                ? url.trim()
                                                : url.replace('/storage/', '/storage/app/')
                                        );
                                    return (
                                        <Col xl={2} md={3} sm={4} xs={6} className='mb-3' key={product.id}>
                                            <div className="new-box">
                                                <div className="img-holder">
                                                    <Link to={"/single-product/" + product.id}>
                                                        <img className="card_img" src={Img2} />
                                                        {/* {cleanedUrl[0] && (
                                                            <img src={cleanedUrl[0]} alt={`${slug} image 1`} />
                                                        )} */}
                                                    </Link>
                                                </div>
                                                <div className="rate-area">
                                                    <ul>
                                                        <li><IoMdStar /></li>
                                                        <li><IoMdStar /></li>
                                                        <li><IoMdStar /></li>
                                                        <li><IoMdStar /></li>
                                                        <li><IoMdStar /></li>
                                                    </ul>
                                                    <span>(05 Review)</span>
                                                </div>
                                                <Link className="cat-title" to={"/single-product/" + product.id}>
                                                    <strong>{cleanSlug}</strong>
                                                </Link>
                                                <p>product.description</p>
                                                <div className="new-prices">
                                                    <span>${product.price}</span><del>${product.special_price}</del>
                                                </div>
                                            </div>
                                        </Col>
                                    )
                                })}
                            </Row>
                            <Link className="btn-style blue-btn" to="/shop">View More Products</Link>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default FeaturedProducts
import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ADD } from "../Redux/actions/CartAction";
import NoImage from '../images/NoImage.jpg'
import { FaStar } from "react-icons/fa";
// import Swal from 'sweetalert2';

const ProductListing = ({ sortedProducts }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.auth.user);
  const handleAddToCart = (Products) => {
    dispatch(ADD(Products));
  };
  // const handleAddToCart = (product) => {
  //   if (isLoggedIn) {
  //     dispatch(ADD(product));
  //     Swal.fire({
  //       title: "Success!",
  //       text: "Added to Cart successfully",
  //       icon: "success",
  //       timer: 3000,
  //       showConfirmButton: false,
  //     });
  //   } else {
  //     Swal.fire({
  //       title: "Please Login",
  //       text: "Please login to add the product to your cart.",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Login",
  //       cancelButtonText: "Cancel",
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         window.location.href = "/login";
  //       }
  //     });
  //   }
  // };
  return (
    <>
      <div className="listing">
        <Row>
          {sortedProducts.map(({ id, price, slug, special_price, description, imagestwo, category }) => {
            const cleanSlug = slug.replace(/-/g, " ");
            const cleanedUrl = imagestwo
              .replace(/[\\[\]"]/g, '')
              .split(',');
            return (
              <>
                <Col xl={3} md={4} sm={10} xs={12} className='mb-3' >
                  <div className="new-box" key={id}>
                    <div className="img-holder">
                      {cleanedUrl ? (
                        <Link to={"/single-product/" + id}>
                          {cleanedUrl[0] && (
                            // <img src={`${process.env.REACT_APP_BASE_URL}/storage/app/${imagestwo}`} alt={`${slug} image 1`} />
                            <img className="card_img" src={`${process.env.REACT_APP_BASE_URL}/storage/app/${cleanedUrl[0]}`} alt={`${slug} image 1`} />
                          )}
                        </Link>
                      ) : (
                        <Link to={"/single-product/" + id}>
                          <img className="card_img" variant="top" src={NoImage} alt="Product img" />
                        </Link>
                      )}
                    </div>
                    <div className="rate-area">
                      <ul>
                        {[...Array(5)].map((_, index) => (
                          <li key={index}><FaStar /></li>
                        ))}
                      </ul>
                      <span>(05 Review)</span>
                    </div>
                    <Link className="cat-title" to={"/single-product/" + id}><strong>{cleanSlug}</strong></Link>
                    <div className="shop_page_text" dangerouslySetInnerHTML={{ __html: description }} />
                    <div className="new-prices">
                      <span>${price}</span><del>${special_price}</del>
                    </div>
                  </div>
                </Col>
              </>
            );
          })}
        </Row>
        {/* <button className="custom_btn_card" onClick={() => handleAddToCart({ id, price, slug, special_price, description, imagestwo, category })}>
                    <Link >Add To Cart</Link>
                  </button> */}
      </div >
    </>
  );
};

export default ProductListing;

import './App.css';
import './default.css';
import './dashboard.css';
import { BrowserRouter, Routes, Route, Outlet } from 'react-router-dom'

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/Contact/Contact';
import Testimonials from './pages/testimonials/Testimonials';
import Wishlist from './pages/wishlist/Wishlist';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import RefundPolicy from './pages/refund-policy/RefundPolicy';
import TermsConditions from './pages/terms-conditions/TermsConditions';
import Blogs from './pages/blogs/Blogs';
import SingleProduct from './pages/SingleProduct/SingleProduct';
import Faqs from './pages/help-&-faq/Faqs';
import Shop from './pages/shop/Shop';

import Login from './pages/login/Login';
import Register from './pages/register/Register';
import Cart from './pages/cart/Cart';
import CheckOut from './pages/checkout/CheckOut';
import OrderTracking from './pages/orderTracking/OrderTracking';
import OrderSearch from './pages/orderTracking/OrderSearch';
import PageNotFound from './pages/PageNotFound/PageNotFound';
import ScrollToTop from './components/ScrollToTop';
import OurStory from './pages/ourStory/OurStory';
import MissionVision from './pages/mission/MissionVision';
import PressMedia from './pages/press&media/PressMedia';
import Careers from './pages/about/Careers';

// ******** dashboard Links ********
import SideBar from './admin/components/sidebar/SideBar';
import StartToTop from './components/StartToTop';
import TranslatePopup from './components/TranslatePopup';
// import { useEffect } from 'react';
import Overview from './admin/pages/Overview';
import Orders from './admin/pages/Orders';
import Addresses from './admin/pages/Addresses';
import TechnicalSupport from './pages/technical-support/TechnicalSupport';
import CustomerService from './pages/customer-service/CustomerService';
import AccountSetting from './admin/pages/AccountSetting';
import DownloadManager from './admin/pages/DownloadManager';
import PaymentMethods from './admin/pages/PaymentMethods';
import Library from './admin/pages/Library';
import { GoogleOAuthProvider } from '@react-oauth/google';
import SellWithUs from './pages/user-product/SellWithUs';
import AuthorToolsResources from './pages/user-product/AuthorToolsResources';
import PublisherDashboard from './pages/user-product/PublisherDashboard';
import UserPayment from './pages/user-product/UserPayment';
import MarketingPromotionServices from './pages/user-product/MarketingPromotionServices';
import MultilingualLanguages from './pages/languages/MultilingualLanguages';
import LanguageLearning from './pages/languages/LanguageLearning';
import LanguageConverter from './pages/languages/LanguageConverter';
import UserType from './pages/userType/UserType';
import AuthorBlog from './admin/pages/AuthorBlog';
import AuthorCorner from './pages/author-corner/AuthorCorner';
import AuthorBlogDetails from './pages/author-corner/AuthorBlogDetails';
import SingleAuthorBlog from './pages/author-corner/SingleAuthorBlog';
import AuthorEvents from './pages/community/AuthorEvents';
import CreateEvent from './admin/pages/community/CreateEvent';
import EventsDetails from './pages/community/EventsDetails';
import ReaderReviews from './pages/community/ReaderReviews';
import InterviewsPodcasts from './pages/community/InterviewsPodcasts';
import InterviewPodcast from './admin/pages/community/InterviewPodcast';
// import Invoice from './admin/pages/invoice/Invoice';

function App() {
  const clientId = '798208744833-nbsrgm6fput2oh088jdbnlqfrbak5dug.apps.googleusercontent.com';
  return (
    <>
      <GoogleOAuthProvider clientId={clientId}>
        <BrowserRouter>
          <TranslatePopup />
          <Header />
          <ScrollToTop />
          <StartToTop />
          <Routes>
            <Route index element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/careers' element={<Careers />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/shop' element={<Shop />} />
            <Route path='/wishlist' element={<Wishlist />} />
            <Route path='/testimonials' element={<Testimonials />} />
            <Route path='/privacy' element={<PrivacyPolicy />} />
            <Route path='/refund' element={<RefundPolicy />} />
            <Route path='/termsConditions' element={<TermsConditions />} />
            <Route path='/register' element={<Register />} />
            <Route path='/user-type' element={<UserType />} />
            <Route path='login' element={<Login />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/checkOut' element={<CheckOut />} />
            <Route path='/single-product/:id' element={<SingleProduct />} />
            <Route path='/our-story' element={<OurStory />} />
            <Route path='/mission-vision' element={<MissionVision />} />
            <Route path='/press-media' element={<PressMedia />} />
            <Route path='/blogs' element={<Blogs />} />
            <Route path='/faqs' element={<Faqs />} />
            <Route path='/technical-support' element={<TechnicalSupport />} />
            <Route path='/customer-service' element={<CustomerService />} />
            <Route path='single-orders/:id' element={<OrderTracking />} />
            <Route path='order-search' element={<OrderSearch />} />
            <Route path='/sell-with-us' element={<SellWithUs />} />
            <Route path='/author-tools-resources/:id' element={<AuthorToolsResources />} />
            <Route path='/publisher-dashboard' element={<PublisherDashboard />} />
            <Route path='/user-payment' element={<UserPayment />} />
            <Route path='/marketing-promotion-services' element={<MarketingPromotionServices />} />
            <Route path='/multilingual-languages' element={<MultilingualLanguages />} />
            <Route path='/language-learning' element={<LanguageLearning />} />
            <Route path='/en-to/:label' element={<LanguageConverter />} />
            <Route path='/author-corner' element={<AuthorCorner />} />
            <Route path="/author-blog-details/:userid" element={<AuthorBlogDetails />} />
            <Route path="/single-author-blog/:id" element={<SingleAuthorBlog />} />
            <Route path="/author-events" element={<AuthorEvents />} />
            <Route path="/events-details/:id" element={<EventsDetails />} />
            <Route path="/reader-reviews" element={<ReaderReviews />} />
            <Route path="/interviews-podcasts" element={<InterviewsPodcasts />} />

            <Route path="*" element={<PageNotFound />} />

            {/********* dashboard Links *********/}
            <Route path="dashboard" element={<WithSidebar />}>
              <Route path="overview" element={<Overview />} />
              <Route path="orders" element={<Orders />} />
              <Route path="addresses" element={<Addresses />} />
              <Route path="account-setting" element={<AccountSetting />} />
              <Route path="download-manager" element={<DownloadManager />} />
              <Route path="payment-methods" element={<PaymentMethods />} />
              <Route path="library" element={<Library />} />
              <Route path="wishlist" element={<Wishlist />} />
              <Route path="author-blog" element={<AuthorBlog />} />
              <Route path="create-event" element={<CreateEvent />} />
              <Route path="interview-podcast" element={<InterviewPodcast />} />
            </Route>
            {/* <Route path="invoice-show/:id" element={<Invoice />} /> */}
          </Routes>
          <Footer />
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

const WithSidebar = () => {
  return (
    <SideBar>
      <Outlet />
    </SideBar>
  );
};

export default App;

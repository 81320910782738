// src/App.js
import React, { useState } from 'react';
import axios from 'axios';

function DownloadManager() {
    const [file, setFile] = useState(null);
    const [language, setLanguage] = useState('en');
    const [downloadLink, setDownloadLink] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);
        formData.append('language', language);

        try {
            const response = await axios.post('http://localhost/thatreadsnew/api/upload', formData, {
                responseType: 'blob',
            });
            const url = URL.createObjectURL(new Blob([response.data]));
            setDownloadLink(url);
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <h1>PDF to MP3 Converter</h1>
            <form onSubmit={handleSubmit}>
                <input type="file" accept="application/pdf" onChange={handleFileChange} required />
                <select value={language} onChange={handleLanguageChange}>
                    <option value="en">English</option>
                    <option value="es">Spanish</option>
                    <option value="fr">French</option>
                </select>
                <button type="submit">Convert to MP3</button>
            </form>
            {downloadLink && (
                <a href={downloadLink} download="output.mp3">Download MP3</a>
            )}
        </div>
    );
}

export default DownloadManager
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import SectionHeader from '../../components/SectionHeader'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Loading from '../../components/Loading';


const InterviewsPodcasts = () => {
    const [loading, setLoading] = useState(false);
    const [interviews, setInterviews] = useState([]);

    useEffect(() => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${baseUrl}/api/show-interview`);
                const active = Object.values(response.data).filter(interview => interview.is_active === 1);
                setInterviews(active);
            } catch (error) {
                console.error('There was an error fetching the data!', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <section className='interviews-podcasts'>
                <SectionHeader title='Interviews & Podcasts' PageLink="Interviews & Podcasts"></SectionHeader>
                <Container>
                    {loading && <Loading />}
                    <div className='interviews-podcasts-main'>
                        {interviews.map(({ id,userid, title, date, description , digital_file, thumbnail}) => (
                            <Row key={id}>
                                <Col md={5}>
                                    <div className='interview-video'>
                                        <img src={`${process.env.REACT_APP_BASE_URL}/storage/app/${thumbnail}`} alt="" />
                                    </div>
                                </Col>
                                <Col md={7}>
                                    <div className='interview-card'>
                                        <h1>{title}</h1>
                                        <h4>{userid}</h4>
                                        <p>Date : {new Date(date).toDateString()}</p>
                                        <p>Description : <span dangerouslySetInnerHTML={{ __html: description }} /></p>
                                        <Link to={digital_file} className='btn-style blue-btn' target="_blank" rel="noopener noreferrer">Watch Now</Link>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Container>
            </section>
        </>
    )
}

export default InterviewsPodcasts